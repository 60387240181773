<template>
  <div class="transmit_sub_container">
    <div class="transmittal_slip_filter adj_sur">
      <div class="label_element_cover">
      <label>Date</label>
      <flat-pickr
        v-model="filter.date"
        :config="configDate"
        placeholder="Select Date"
        name="birthdate"
        @on-close="fetchCourierData"
      ></flat-pickr>
      </div>
      <div class="label_element_cover">
      <label v-if="$route.params.type == 'Hub'">Select Courier</label>
      <select
        v-model="filter.provider_id"
        @change="fetchServices"
        v-if="$route.params.type == 'Hub'"
      >
        <option value>Select Provider</option>
        <option
          v-for="(provider,i) in providers"
          :key="'provider_dropdown'+i"
          :value="provider.id"
        >{{provider.name}}</option>
      </select>
      </div>
      <div class="label_element_cover">
      <label>Service</label>
      <select
        v-model="filter.service_id"
        :disabled="services.length == 0"
        v-if="$route.params.type == 'Hub'"
        @change="fillServiceName"
      >
        <option value>Select Service</option>
        <option
          :value="service.id"
          v-for="(service,i) in services"
          :key="'service_dropdown'+i"
        >{{service.name}}</option>
      </select>
      <input type="text" v-model="service_name" disabled v-else />
      </div>
      <div class="bt_generate_label_cover">
      <input type="button" value="Submit" @click="fetchCourierData" />
      </div>
    </div>
    <section>
      <div class="form_section_transmit">
        <table class="transmit_table1">
          <tr>
            <td>&nbsp;</td>
            <td>
              DISPATCH DELIVERY NOTE FOR {{moment(this.filter.date).format("MM-DD-YYYY")}}
              <br />
              <span class="small_rel">
                <span v-if="auth().user_type != 'user'" class="mr-3">{{courier_name}}</span>
                <span>{{service_name}}</span>
              </span>
            </td>
            <td>DISPATCH DATE: {{moment(this.filter.date).format("MM-DD-YYYY")}}</td>
          </tr>
        </table>
      </div>
    </section>
    <loader v-if="loading" />
    <div v-if="!loading && Object.keys(list).length > 0">
      <section>
        <div class="form_section_transmit">
          <div class="ddnote_table_structure ddnote_table_structure_print">
            <div class="ddnote_row">
              <div></div>
              <div>HEADER BARCODE</div>
              <div>ITEM BARCODE</div>
              <div>ADDRESSEE</div>
              <div>ITEM</div>
              <div>ITEM DESCRIPTION</div>
              <div>SENDER</div>
              <div>CHECKED</div>
            </div>
            <div
              :class="slip.is_checked ? 'ddnote_row':'ddnote_row hide-row'"
              v-for="(slip,i) in list"
              :key="'slip_'+i"
            >
              <div>
                <input type="checkbox" class="dispatch_chk" v-model="slip.is_checked" />
              </div>
              <div>
                <barcode :value="slip.header_barcode" tag="img" class="barcode_1" />
              </div>
              <div class="have_sub">
                <div class="sub_row" v-for="(courier,j) in slip.bookings" :key="'courier_'+i+j">
                  <div class="sub_cells">
                    <barcode
                      :value="courier.booking_prefix + courier.booking_id"
                      tag="img"
                      class="barcode_2"
                    />
                  </div>
                  <div>
                    <p v-if="courier.delivery_name">{{courier.delivery_name}}</p>
                    <p v-if="courier.delivery_address_1">{{courier.delivery_address_1}}</p>
                    <p v-if="courier.delivery_address_2">{{courier.delivery_address_2}}</p>
                    <p v-if="courier.delivery_city">{{courier.delivery_city}}</p>
                    <p v-if="courier.delivery_state">{{courier.delivery_state}}</p>
                    <p v-if="courier.delivery_country">{{courier.delivery_country}}</p>
                  </div>
                  <div>{{courier.item_qty}}</div>
                  <div>{{courier.goods_description || 'NA'}}</div>
                  <div>{{courier.pickup_name}}</div>
                  <div></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <h3>{{totalCheckedSlips}} Total Slips</h3>
        <div class="form_section_transmit">
          <table class="transmit_table4">
            <tr>
              <td>
                <div class="small_text">Prepared by (Branch Representative)</div>
                <div class="big_text">{{this.user.name}}</div>
                <br />Signature over Printed Name
              </td>
              <td>
                <div class="big_text">{{moment(this.filter.date).format("MM/DD/YYYY")}}</div>Date
              </td>
              <td>
                <div class="small_text">Checked By: (Branch Personal)</div>
                <hr />
                <div class="small_text">Signature over Printed Name</div>
              </td>
              <td>
                <div class="big_text">{{moment(this.filter.date).format("MM/DD/YYYY")}}</div>Date
              </td>
            </tr>
            <tr>
              <td>
                <div class="small_text">Acknowledged By: (Recipient)</div>

                <br />
                <hr />Signature over Printed Name
              </td>
              <td>
                <div class="big_text">{{moment(this.filter.date).format("MM/DD/YYYY")}}</div>
                <hr />Date
              </td>
              <td></td>
              <td>
                <div class="big_text">{{moment(new Date(Date.now())).format("HH:mm:ss")}}</div>Time
              </td>
            </tr>
            <tr>
              <td colspan="4"></td>
            </tr>
          </table>
        </div>
      </section>
      <section class="centered">
        <a class="link_bt bt_cancel" @click="$router.push('/pages/dashboard')">Cancel</a>
        <a class="link_bt bt_save" @click="printDispatchNote">Print</a>
        <!--<a class="link_bt bt_save">Save and Print</a>-->
      </section>
    </div>
    <h3 v-if="Object.keys(list).length == 0 && !loading">No data found</h3>
  </div>
</template>
<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

export default {
  name: "x",
  components: {
    flatPickr
  },
  computed: {
    totalCheckedSlips() {
      return this.list.filter(element => element.is_checked).length;
    }
  },
  created() {
    this.$emit("breadCrumbData", [
      this.$route.params.type ? "Hub Dispatch" : "Dispatch",
      "Dispatch Delivery Note"
    ]);
    this.fetchData();
  },
  data() {
    return {
      filter: {
        date: "",
        provider_id: "",
        service_id: ""
      },
      user: {
        name: "",
        branch_code: "",
        division: ""
      },
      courier_name: "",
      service_name: "",
      providers: [],
      services: [],
      list: [],
      enableTime: true,
      configTime: {
        enableTime: true,
        altInput: true,
        noCalendar: true
      },
      configDate: {
        altInput: true,
        noCalendar: false,
         format: "d-m-Y",
          altFormat: "d-m-Y",
      },
      loading: true
    };
  },
  methods: {
    fetchData() {
      this.filter = {
        date: this.moment(new Date(Date.now())).format("YYYY-MM-DD"),
        provider_id: "",
        service_id: ""
      };
      this.courier_name = "";
      this.list = [];
      if (this.$route.params.type == "Hub") {
        this.service_name = "";
      } else {
        this.service_name = "Messenger";
      }
      this.fetchProviders();
      this.fetchCourierData();
      this.fetchUser();
    },
    fetchUser() {
      this.axios
        .get("/api/user/data")
        .then(response => {
          this.user.name = response.data.msg.name;
          this.user.branch_code = response.data.msg.branch.branch_id;
          this.user.division = response.data.msg.division.name;
        })
        .catch(error => {
          this.toast.error(error);
        });
    },
    fetchProviders() {
      this.axios
        .get("api/provider")
        .then(response => {
          this.providers = response.data.providers;
        })
        .catch(error => {
          console.log(error);
        });
    },
    fetchServices() {
      this.fillCourierName();
      this.services = [];
      this.filter.service_id = "";
      this.service_name = "";
      this.axios
        .get("api/provider/services/" + this.filter.provider_id)
        .then(response => {
          this.services = response.data.services;
        })
        .catch(error => {
          console.log(error);
        });
      this.fetchCourierData();
    },
    fillCourierName() {
      this.courier_name = this.filter.provider_id
        ? this.providers.find(element => element.id == this.filter.provider_id)
            .name
        : "";
    },
    fillServiceName() {
      this.service_name = this.filter.service_id
        ? this.services.find(element => element.id == this.filter.service_id)
            .name
        : "";
      this.fetchCourierData();
    },
    fetchCourierData() {
      this.list = [];
      if (this.$route.params.type == "Hub") {
        this.fetchHubSlips();
      } else {
        this.fetchSlips();
      }
    },
    fetchSlips() {
      this.loading = true;
      this.axios
        .post("api/slip/dispatch/list", this.filter)
        .then(response => {
          this.list = Object.keys(response.data.list).map(element => {
            return {
              header_barcode: element,
              is_checked: true,
              bookings: response.data.list[element]
            };
          });
          console.log(this.list);
          this.loading = false;
        })
        .catch(error => {
          console.log(error);
          this.loading = false;
        });
    },
    fetchHubSlips() {
      this.loading = true;
      this.axios
        .post("api/hub/slip/dispatch/list", this.filter)
        .then(response => {
          console.log(response);
          this.list = Object.keys(response.data.list).map(element => {
            return {
              header_barcode: element,
              is_checked: true,
              bookings: response.data.list[element]
            };
          });
          this.loading = false;
        })
        .catch(error => {
          console.log(error);
          this.loading = false;
        });
    },
    printDispatchNote() {
      if (this.totalCheckedSlips > 0) {
        window.print();
      } else {
        this.toast.error("No slips selected");
      }
    }
  },
  watch: {
    $route(to, from) {
      this.$emit("breadCrumbData", [
        this.$route.params.type ? "Hub Dispatch" : "Dispatch",
        "Dispatch Delivery Note"
      ]);
      this.fetchData();
    }
  }
};
</script>
<style scoped>
.barcode_1 {
  height: 100px;
  width: 100%;
}
.barcode_2 {
  height: 70px;
  width: 100%;
}
.small_rel {
  font-size: 60%;
}
.transmit_table3 tr td:first-child {
  min-width: 40px;
}
</style>
<style lang="scss">
.ddnote_table_structure {
  width: 100%;
  height: auto;
  border: 2px solid #333;
  border-right: 0;
  border-bottom: 0;
  margin: 20px 0;
  .ddnote_row {
    display: flex;
    font-size: 14px;
    .bar_text {
      font-size: 13px;
    }
    &:first-child {
      font-weight: 700;
      font-size: 16px;
    }
    > div {
      padding: 10px;
      width: 100%;
      height: auto;
      min-height: 50px;
      background: #fff;
      border: 2px solid #333;
      border-left: 0;
      border-top: 0;
      text-align: center;

      &:nth-child(1) {
        width: 50px;
        flex-shrink: 0;
      }
      &:nth-child(2) {
        width: 250px;
        flex-shrink: 0;
      }
      &:nth-child(3) {
        width: 250px;
        flex-shrink: 0;
      }

      &:nth-child(4) {
        min-width: 250px;
      }
      &:nth-child(5) {
        width: 80px;
        flex-shrink: 0;
      }
      &:nth-child(6) {
        min-width: 250px;
      }
      &:nth-child(7) {
        width: 180px;
        flex-shrink: 0;
      }
      &:nth-child(8) {
        width: 100px;
        flex-shrink: 0;
      }
      &:nth-child(3).have_sub {
        padding: 0;
        width: 100%;
        flex-shrink: 1;
        .sub_row {
          width: 100%;
          display: flex;
          min-height: 180px;
          > div {
            padding: 10px;
            border-right: 2px solid #333;
            border-bottom: 2px solid #333;
            word-wrap: break-word;
            &:nth-child(1) {
              width: 250px;
              flex-shrink: 0;
              padding: 10px;
            }
            &:nth-child(2) {
              min-width: 250px;
              width: 100%;
            }
            &:nth-child(3) {
              width: 80px;
              flex-shrink: 0;
            }
            &:nth-child(4) {
              width: 100%;
              min-width: 250px;
            }
            &:nth-child(5) {
              width: 180px;
              flex-shrink: 0;
            }
            &:nth-child(6) {
              width: 98px;
              flex-shrink: 0;
              border-right: 0;
            }
          }
          &:last-child > div {
            border-bottom: 0;
          }
        }
      }
    }
  }
}
.mr-3 {
  margin-right: 1.5rem;
}
@media only screen and (max-width: 1900px) {
  .ddnote_table_structure .ddnote_row > div:nth-child(2) {
    width: 200px;
  }
  .ddnote_table_structure .ddnote_row > div:nth-child(3) {
    width: 200px;
  }
  .ddnote_table_structure
    .ddnote_row
    > div:nth-child(3).have_sub
    .sub_row
    > div:nth-child(1) {
    width: 200px;
  }
  .ddnote_table_structure .ddnote_row > div:nth-child(4) {
    min-width: 150px;
  }
  .ddnote_table_structure
    .ddnote_row
    > div:nth-child(3).have_sub
    .sub_row
    > div:nth-child(2) {
    min-width: 150px;
  }
  .ddnote_table_structure .ddnote_row > div:nth-child(5) {
    width: 60px;
  }
  .ddnote_table_structure
    .ddnote_row
    > div:nth-child(3).have_sub
    .sub_row
    > div:nth-child(3) {
    width: 60px;
  }
  .ddnote_table_structure .ddnote_row > div:nth-child(6) {
    min-width: 150px;
  }
  .ddnote_table_structure
    .ddnote_row
    > div:nth-child(3).have_sub
    .sub_row
    > div:nth-child(4) {
    min-width: 150px;
  }
  .ddnote_table_structure .ddnote_row > div:nth-child(7) {
    width: 120px;
  }
  .ddnote_table_structure
    .ddnote_row
    > div:nth-child(3).have_sub
    .sub_row
    > div:nth-child(5) {
    width: 120px;
  }
}
@media only screen and (max-width: 1400px) {
  .ddnote_table_structure .ddnote_row > div:nth-child(2) {
    width: 160px;
  }
  .ddnote_table_structure .ddnote_row > div:nth-child(3) {
    width: 160px;
  }
  .ddnote_table_structure
    .ddnote_row
    > div:nth-child(3).have_sub
    .sub_row
    > div:nth-child(1) {
    width: 160px;
  }
}

@media print {
  .hide-row {
    display: none !important;
  }
  /*
  .ddnote_table_structure_print {
    width: 100%;
    height: auto;
    border: 2px solid #333;
    border-right: 0;
    border-bottom: 0;
    margin: 20px 0;
    font-size: 13px;
    .ddnote_row {
      display: flex;
      font-size: 13px;
      .bar_text {
        font-size: 12px;
      }
      &:first-child {
        font-weight: 700;
        font-size: 13px;
      }
      > div {
        padding: 5px;
        width: 100%;
        height: auto;
        min-height: 50px;
        border: 2px solid #333;
        border-left: 0;
        border-top: 0;
        text-align: center;
        &:nth-child(1) {
          width: 0px;
          flex-shrink: 0;
          padding: 0;
          border-right: 0;
          input {
            display: none;
          }
        }
        &:nth-child(2) {
          width: 130px;
          flex-shrink: 0;
        }

        &:nth-child(3) {
          width: 130px;
          flex-shrink: 0;
        }
        &:nth-child(4) {
          width: 120px !important;
          min-width: 120px !important;
          flex-shrink: 0;
        }
        &:nth-child(5) {
          width: 60px !important;
          min-width: 60px !important;
          flex-shrink: 0;
        }
        &:nth-child(6) {
          width: 120px;
          min-width: 120px;
          flex-shrink: 0;
        }
        &:nth-child(7) {
          width: 88px;
          min-width: 88px;
          flex-shrink: 1;
        }
        &:nth-child(8) {
          width: 70px;
          flex-shrink: 0;
        }
        &:nth-child(3).have_sub {
          padding: 0;
          width: 100%;
          flex-shrink: 1;
          .sub_row {
            width: 100%;
            display: flex;
            > div {
              padding: 2px;
              border-right: 2px solid #333;
              border-bottom: 2px solid #333;
              word-wrap: break-word;
              &:nth-child(1) {
                width: 130px;
                flex-shrink: 0;
                padding: 2px;
              }
              &:nth-child(2) {
                width: 120px;
                min-width: 120px;
                flex-shrink: 0;
              }
              &:nth-child(3) {
                width: 60px;
                flex-shrink: 0;
              }
              &:nth-child(4) {
                width: 120px;
                min-width: 120px;
                flex-shrink: 0;
              }
              &:nth-child(5) {
                width: 88px;
                min-width: 88px;
                max-width: 88px;
                flex-shrink: 1;
              }
              &:nth-child(6) {
                width: 68px;
                min-width: 68px;
                flex-shrink: 0;
              }
            }
            &:last-child > div {
              border-bottom: 0;
            }
          }
        }
      }
    }
  }
  .transmit_table1 tr td:nth-child(2) {
    font-size: 14px;
  }*/
  .ddnote_table_structure_print {
    width: 100%;
    height: auto;
    border: 2px solid #333;
    border-right: 0;
    border-bottom: 0;
    margin: 20px 0;
    font-size: 13px;
    .ddnote_row {
      display: flex;
      font-size: 12px;
      .bar_text {
        font-size: 12px;
      }
      &:first-child {
        font-weight: 700;
        font-size: 12px;
      }
      > div {
        padding: 2px;
        width: 100%;
        height: auto;
        min-height: 50px;
        border: 2px solid #333;
        border-left: 0;
        border-top: 0;
        text-align: center;
        &:nth-child(1) {
          width: 0px;
          flex-shrink: 0;
          padding: 0;
          border-right: 0;
          input {
            display: none;
          }
        }
        &:nth-child(2) {
          width: 130px;
          flex-shrink: 0;
        }

        &:nth-child(3) {
          width: 130px;
          flex-shrink: 0;
        }
        &:nth-child(4) {
          width: 120px !important;
          min-width: 120px !important;
          flex-shrink: 0;
        }
        &:nth-child(5) {
          width: 60px !important;
          min-width: 60px !important;
          flex-shrink: 0;
        }
        &:nth-child(6) {
          width: 120px;
          min-width: 120px;
          flex-shrink: 0;
        }
        &:nth-child(7) {
          width: 100%;
          min-width: 65px;
          flex-shrink: 1;
          background: #fff;
        }
        &:nth-child(8) {
          width: 70px;
          flex-shrink: 0;
        }
        &:nth-child(3).have_sub {
          padding: 0;
          width: 100%;
          flex-shrink: 1;
          .sub_row {
            width: 100%;
            display: flex;
            > div {
              padding: 2px;
              border-right: 2px solid #333;
              border-bottom: 2px solid #333;

              &:nth-child(1) {
                width: 130px;
                flex-shrink: 0;
                padding: 2px;
              }
              &:nth-child(2) {
                width: 120px;
                min-width: 120px;
                flex-shrink: 0;
              }
              &:nth-child(3) {
                width: 60px;
                flex-shrink: 0;
              }
              &:nth-child(4) {
                width: 120px;
                min-width: 120px;
                flex-shrink: 0;
              }
              &:nth-child(5) {
                width: 100%;
                min-width: 65px;
                flex-shrink: 1;
                background: #fff;
              }
              &:nth-child(6) {
                width: 68px;
                min-width: 68px;
                flex-shrink: 0;
              }
            }
            &:last-child > div {
              border-bottom: 0;
            }
          }
        }
      }
    }
  }
  .transmit_table1 tr td:nth-child(2) {
    font-size: 14px;
  }
}
</style>